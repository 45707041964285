
var moment = require('moment');
function padLeftZero(str) {
    return ('00' + str).substr(str.length)
}
String.prototype.ExtStr=function(val){
  var index=this.indexOf(val);
  if(index<0){
   return this+"("+val+")";
  }
  else return this;
}
var utils = {

   ///判断院校名称中是否含有招考类型的名字
   FormatZkTypeStr:(schname,typename)=>{
    if(schname==null||schname==undefined)return typename;
    var index=schname.indexOf(typename);
    if(index<0){
      return typename;
     }
     else return '';
},
    // 将秒数转化为分钟：秒的时间格式
    toTime: (sec) => {
        //秒数转化为mm:ss形式
        let s = sec % 60 < 10 ? "0" + (sec % 60) : sec % 60;
        let min =
            Math.floor(sec / 60) < 10 ?
            "0" + Math.floor(sec / 60) :
            Math.floor(sec / 60);
        return min + ":" + s;
    },
    toTime2: (sec) => {
        //秒数转化为mm:ss形式
        let s = sec % 60 < 10 ? "0" + (sec % 60) : sec % 60;
        let min =Math.floor(sec%3600 /60);
        let minstr="";
        if(min<10) minstr="0"+min;
        else minstr=min+"";
        let hour=Math.floor(sec/3600);
    
        if(hour>0)
        return hour+":"+minstr + ":" + s;
        else
        {
            return minstr + ":" + s;
        }
    },
  
    // 将时间格式转化为 指定的格式 如“yyyy-MM-dd”
    dateFormat: (date, fmt) => {
        if (!date) return "";
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds()
        }
        for (let k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                let str = o[k] + ''
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
            }
        }
        return fmt
    },
    getRandomCode: (len) => {
        let str = "1234567890abcdefghijkmnpqrstuvwxyz";
        let l = str.length;
        let aim = "";
        if (!isNaN(len)) len = 4;
        while (aim.length < len) {
            let index = Math.floor((Math.random() * l));
            aim += str[index];
        }
        return aim;
    },

      // 格式化时间同年不显示年，不显示秒
      SxtFormatDate: (datstr) => {
        
        if (!datstr) return "";
        let dststr="";
        let m1=moment(datstr);
        //console.log(m1);
        let m2=moment();
        if(m1.year()!=m2.year()){
          dststr+=""+m1.year()+"-";
      }
        dststr+=((m1.month()+1)<10?"0":"")+(m1.month()+1);
        dststr+="-"+(m1.date()<10?"0":"")+m1.date();
        dststr+=" "+(m1.hour()<10?"0"+m1.hour():m1.hour());
        dststr+=":"+(m1.minute()<10?"0"+m1.minute():m1.minute());

        // let date1 = new Date(datstr);
        // let nowdate=new Date();

        
        // if(date1.getFullYear()!=nowdate.getFullYear()){
        //     dststr+=""+date1.getFullYear()+"-";
        // }
        // dststr+=((date1.getMonth()+1)<10?"0":"")+(date1.getMonth()+1);
        // dststr+="-"+(date1.getDate()<10?"0":"")+date1.getDate();
        // dststr+=" "+(date1.getHours()<10?"0"+date1.getHours():date1.getHours());
        // dststr+=":"+(date1.getMinutes()<10?"0"+date1.getMinutes():date1.getMinutes());
        return dststr;
    },
    // 判断给定的时间串是否是 过去的 日期 （注意是日期，而不是时间）
    isPastDay: (datstr) => {
        if (!datstr) return true;
        let date1 = new Date(datstr);
        let date2 = new Date()
        date2.setHours(23);
        date2.setMinutes(59);
        date2.setSeconds(59);
        return date1 < date2;
    },
    // 判断给定的两个时间串的时间大小关系 -1 表小于 0表 相等  1表 大于
    compareDate: (datstr1, date2str) => {
        let date1 = new Date(datstr1);
        let date2 = new Date(date2str);
        if (date1.getTime() == date2.getTime()) return 0;
        if (date1.getTime() < date2.getTime()) return -1;
        return 1;
    },
    log: (str) => {
        if (process.env.NODE_ENV == 'development') {
            console.log(str);
        }
    },
    //替换成高亮文本
    highlight:(str,keys)=>{
     
      if(keys==undefined||keys.length==0)return str;
      for(var i=0;i<keys.length;i++){
            let element=keys[i];
            if(str.indexOf(element)!=-1)
            {
              str=str.replace(element,"<span style='color:red'>"+element+"</span>");
              break;
            }
      }
      return str;
    },
    //对象的 排序 方法
    //key 数组对象的属性名，isAsc 是否是升序
    //使用：arr.sort(sortExp('price',true));
    sortExp: (key, isAsc) => {
        return function (x, y) {
            if (isNaN(key)) {
                if (x[key] > y[key]) {
                    return 1 * (isAsc ? 1 : -1);

                } else if (x[key] < y[key]) {
                    return -1 * (isAsc ? 1 : -1);
                } else {
                    return 0;
                }
            } else {
                return (x[key] - y[key]) * (isAsc ? 1 : -1)
            }
        }
    },
    dateShortCuts:()=>{
        let s=[
        {
          text: "今天",
          value: () => {
            const end = new Date();
            const start = new Date();
            //start.setTime(end.getTime() - 3600 * 1000 * 24);
            return [start, end];
          },
        },
        {
          text: "近两天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end];
          },
        },
        {
          text: "近三天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 2);
            return [start, end];
          },
        },
        {
          text: "近一周",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "近一月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "本月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(1);
            return [start, end];
          },
        },
        {
          text: "上月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(1);
            start.setMonth(start.getMonth() - 1);
            end.setDate(1);
            let timestart = end.getTime();
            end.setTime(timestart - 3600 * 1000 * 24);
            return [start, end];
          },
        },
      ];
    return s},
	//返回与往年计划的人数差
	GetDiffPlanCount:(mobanname,planyear,curplancount,strscoreinfo,newplan)=>{
		let diffcount = 0;
		let jsonArray = JSON.parse(strscoreinfo);
		if(mobanname.includes('模拟')){
			planyear = planyear - 1;
		}
		let hisplancount = jsonArray.filter(item=>item.Year == planyear-1)[0].PlanCount;
		if(hisplancount!=''){
			diffcount = curplancount - hisplancount;
		}
		//return diffcount;
		var strhtml = '<span>'+curplancount+'</span><br>';
		if(diffcount>0){
			strhtml += '<span title="与上一年相比增加 '+diffcount+' 人"  style="color:#67c23a;">+'+diffcount+'</span><br>';
		}else if(diffcount<0){
			strhtml += '<span title="与上一年相比减少 '+-diffcount+' 人"  style="color:#f56c6c;">'+diffcount+'</span><br>';
		}else{
			//只有非新增院校或新增专业时才显示+0
			if(newplan==0){
				strhtml += '<span title="与上一年相比增加 0 人" >+0</span><br>';
			}
		}
		return strhtml;
	},
}
export default utils